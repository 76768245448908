import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe, faPhone, faCoins } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

const ContactSection = () => {
  return (
    <section id="contact" className="h-screen flex items-center justify-center bg-gray-100">
      <div className="container mx-auto p-4 text-center">
        <h2 className="text-4xl font-bold mb-4">Contact</h2>
        <p className="text-lg">You can reach me at:</p>
        <p className="text-lg">
          <FontAwesomeIcon icon={faEnvelope} /> Email: 
          <a href="mailto:rahmatkrneea@gmail.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 ml-2">rahmatkrneea@gmail.com</a>
        </p>
        <p className="text-lg">
          <FontAwesomeIcon icon={faPhone} /> WhatsApp: 
          <a href="https://wa.me/6281214654145" target="_blank" rel="noopener noreferrer" className="text-blue-500 ml-2">+62812 1465 4145</a>
        </p>
        <p className="text-lg">
          <FontAwesomeIcon icon={faGlobe} /> Website: 
          <a href="https://neea.my.id" target="_blank" rel="noopener noreferrer" className="text-blue-500 ml-2">https://neea.my.id</a>
        </p>
        <p className="text-lg">Address: Bandung, West Java, Indonesia</p>
        <div className="flex justify-center mt-4 space-x-4">
          <a href="https://saweria.co/krneea" target="_blank" rel="noopener noreferrer" className="text-blue-500">
            <FontAwesomeIcon icon={faCoins} size="2x" />
          </a>
          <a href="https://www.linkedin.com/in/krneea" target="_blank" rel="noopener noreferrer" className="text-blue-500">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
          <a href="https://www.tiktok.com/@rahmatkrneea" target="_blank" rel="noopener noreferrer" className="text-blue-500">
            <FontAwesomeIcon icon={faTiktok} size="2x" />
          </a>
          <a href="https://www.youtube.com/@Rahmatkrneea" target="_blank" rel="noopener noreferrer" className="text-blue-500">
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
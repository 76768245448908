import React from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const BackToTopButton = () => {
  return (
    <div className="fixed bottom-4 right-4">
      <Link
        to="landing"
        smooth={true}
        duration={500}
        className="bg-blue-500 text-white py-2 px-4 rounded-full font-poppins cursor-pointer flex items-center justify-center"
        style={{ width: '40px', height: '40px' }}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </Link>
    </div>
  );
}

export default BackToTopButton;
import React from 'react';

const AboutSection = () => {
  return (
    <section id="about" className="h-screen flex items-center justify-center bg-gray-100">
      <div className="container mx-auto p-4">
        <h2 className="text-4xl font-bold mb-4">About Me</h2>
        <p className="text-lg mb-4">
          I am a passionate programmer with expertise in Laravel, React, and Flutter. I have been working in the industry for several years and have a strong portfolio of successful projects. Currently, I am working as a freelancer, taking on exciting new challenges and opportunities.
        </p>
      </div>
    </section>
  );
}

export default AboutSection;
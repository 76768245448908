import React from 'react';
import { Link } from 'react-scroll';

const LandingSection = () => {
  return (
    <section id="landing" className="bg-gray-900 text-white h-screen flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-6xl font-bold mb-4 font-poppins">Welcome to My Portfolio</h1>
        <p className="text-2xl mb-8 font-poppins">I am a programmer specializing in Laravel, React, and Flutter.</p>
        <Link
          to="about"
          smooth={true}
          duration={500}
          className="bg-blue-500 text-white py-2 px-4 rounded font-poppins cursor-pointer"
        >
          Learn More
        </Link>
      </div>
    </section>
  );
}

export default LandingSection;
import React from 'react';

const projects = [
  {
    title: "School Management System",
    description: "A comprehensive solution for managing school operations, including student records, attendance, and grade tracking.",
    imageUrl: "https://example.com/school-management-system.png"
  },
  {
    title: "Hospital Management System",
    description: "An advanced system to manage hospital operations, patient records, billing, and appointments.",
    imageUrl: "https://example.com/hospital-management-system.png"
  },
  {
    title: "University Management System",
    description: "A robust system for managing university operations, including course registration, faculty management, and student services.",
    imageUrl: "https://example.com/university-management-system.png"
  },
  {
    title: "Employee Management System",
    description: "A solution for managing employee records, payroll, performance evaluations, and attendance.",
    imageUrl: "https://example.com/employee-management-system.png"
  },
  {
    title: "Multi Level Marketing System",
    description: "A platform to manage multi-level marketing operations, including sales tracking, network management, and commission calculations.",
    imageUrl: "https://example.com/multi-level-marketing-system.png"
  },
  {
    title: "Koisanworld: NFT Game",
    description: "An innovative NFT game that allows players to collect, trade, and battle unique digital creatures.",
    imageUrl: "https://example.com/koisanworld-nft-game.png"
  },
  {
    title: "Pasopati: Attendance & Patroli by GPS",
    description: "A GPS-based solution for attendance tracking and patrolling, ensuring accurate and real-time data.",
    imageUrl: "https://example.com/pasopati-attendance-patroli.png"
  },
];

const LatestWorkSection = () => {
  return (
    <section id="latest-work" className="bg-gray-200 py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">Latest Work</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div key={index} className="bg-white p-6 rounded shadow-lg">
              <img src={project.imageUrl} alt={project.title} className="w-full h-48 object-cover rounded mb-4" />
              <h3 className="text-2xl font-semibold mb-2">{project.title}</h3>
              <p className="text-lg">{project.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default LatestWorkSection;
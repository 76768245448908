import React from 'react';

const SkillsSection = () => {
  return (
    <section id="skills" className="h-screen flex items-center justify-center bg-gray-200">
      <div className="container mx-auto p-4 text-center">
        <h2 className="text-4xl font-bold mb-4">Skills</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded shadow">
            <img src="https://laravel.com/img/logomark.min.svg" alt="Laravel" className="mx-auto mb-4 w-16 h-16" />
            <h3 className="text-2xl font-semibold mb-2">Laravel</h3>
            <p className="text-lg">Expert in building web applications with PHP's Laravel framework.</p>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSg1MndL-Xp1JcnqaB0YOqTp6zDjrwYyGKsPA&s" alt="React" className="mx-auto mb-4 w-16 h-16" />
            <h3 className="text-2xl font-semibold mb-2">React</h3>
            <p className="text-lg">Experienced in developing dynamic user interfaces using React.js.</p>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <img src="https://seeklogo.com/images/F/flutter-logo-5086DD11C5-seeklogo.com.png" alt="Flutter" className="mx-auto mb-4 w-16 h-16" />
            <h3 className="text-2xl font-semibold mb-2">Flutter</h3>
            <p className="text-lg">Skilled in creating natively compiled applications for mobile and web with Flutter.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SkillsSection;
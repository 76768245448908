import React from 'react';
import Navbar from './components/Navbar';
import LandingSection from './components/LandingSection';
import AboutSection from './components/AboutSection';
import SkillsSection from './components/SkillsSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import LatestWorkSection from './components/LatestWorkSection';
import BackToTopButton from './components/BackToTopButton';

const App = () => {
  return (
    <div>
      <Navbar />
      <LandingSection />
      <AboutSection />
      <SkillsSection />
      <LatestWorkSection />
      <ContactSection />
      <Footer />
      <BackToTopButton />
    </div>
  );
}

export default App;
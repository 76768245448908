import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-800 p-4 text-white fixed w-full z-10 top-0">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-2xl">Neea</div>
        <div className="md:hidden" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} className="cursor-pointer text-2xl" />
        </div>
        <div className="hidden md:flex space-x-8">
          <Link
            to="landing"
            smooth={true}
            duration={500}
            className="hover:text-blue-400 cursor-pointer"
          >
            Home
          </Link>
          <Link
            to="about"
            smooth={true}
            duration={500}
            className="hover:text-blue-400 cursor-pointer"
          >
            About
          </Link>
          <Link
            to="skills"
            smooth={true}
            duration={500}
            className="hover:text-blue-400 cursor-pointer"
          >
            Skills
          </Link>
          <Link
            to="latest-work"
            smooth={true}
            duration={500}
            className="hover:text-blue-400 cursor-pointer"
          >
            Latest Work
          </Link>
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="hover:text-blue-400 cursor-pointer"
          >
            Contact
          </Link>
        </div>
      </div>
      <div className={`fixed top-0 right-0 h-full w-64 bg-gray-800 text-white transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}>
        <div className="p-4">
          <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-2xl mb-4" onClick={toggleMenu} />
          <div className="flex flex-col space-y-4">
            <Link
              to="landing"
              smooth={true}
              duration={500}
              className="hover:text-blue-400 cursor-pointer"
              onClick={toggleMenu}
            >
              Home
            </Link>
            <Link
              to="about"
              smooth={true}
              duration={500}
              className="hover:text-blue-400 cursor-pointer"
              onClick={toggleMenu}
            >
              About
            </Link>
            <Link
              to="skills"
              smooth={true}
              duration={500}
              className="hover:text-blue-400 cursor-pointer"
              onClick={toggleMenu}
            >
              Skills
            </Link>
            <Link
              to="latest-work"
              smooth={true}
              duration={500}
              className="hover:text-blue-400 cursor-pointer"
              onClick={toggleMenu}
            >
              Latest Work
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              className="hover:text-blue-400 cursor-pointer"
              onClick={toggleMenu}
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;